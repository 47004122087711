import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Paragraph, TextContainer, Ul } from "@siteimprove/fancylib";
import { ColorPinkDarker } from "@siteimprove/fancylib/design-tokens/variables";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Header,
	DocsTable,
	Code,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { ProgressBar } from "./progress-bar";

export const Meta: DocPageMeta = {
	category: "Data Visualization",
	title: "Progress Bar",
	notepad: "https://hackmd.io/oxZtjMpDRnCMckHPOVxZmA",
};

export default (): JSX.Element => {
	return (
		<>
			<HeaderSection
				title="Progess Bar"
				subTitle="Visually communicate the progress of a specific process to keep users informed and manage expectations."
			/>
			<ContentSection>
				<TextContainer article>
					<LabWarning />
					<ImportExample lab component={ProgressBar} />
					<Header.H3>Spinner vs. Progress Bar</Header.H3>
					<Paragraph>
						To clarify the differences between <Code>Spinner</Code> and <Code>ProgressBar</Code>, a
						short description is provided.
					</Paragraph>
					<DocsTable>
						<thead>
							<tr>
								<th>Component name</th>
								<th>Purpose</th>
								<th>User interaction</th>
								<th>Usage</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Spinner</td>
								<td>Indicates an ongoing process with an unknown or indefinite duration.</td>
								<td>Typically, no user interaction is possible during the process.</td>
								<td>Loading content, refreshing data, background tasks.</td>
							</tr>
							<tr>
								<td>Progress Bar</td>
								<td>Shows the progress of a process with a known or estimated duration.</td>
								<td>User interaction may be possible during the process (e.g., cancellation).</td>
								<td>Uploading/downloading files, multi-step forms, progress towards goals.</td>
							</tr>
						</tbody>
					</DocsTable>
					<Header.H3>Warm color v.s Cool color</Header.H3>
					<Paragraph>
						To clarify the <Code>colorRange</Code> differences between <Code>Cool</Code> and{" "}
						<Code>Warm</Code>, a short description is provided.
					</Paragraph>
					<DocsTable>
						<thead>
							<tr>
								<th>Color</th>
								<th>Psychological associations</th>
								<th>Use Cases</th>
								<th>Considerations</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Cool Colors (Blues)</td>
								<td>Trust, calmness, professionalism, stability</td>
								<td>
									Standard progress, data uploads, background tasks, tasks with longer durations.
								</td>
								<td>None.</td>
							</tr>
							<tr>
								<td>Warm Colors (Reds, Oranges, Yellows)</td>
								<td>Energy, urgency, excitement, warning.</td>
								<td>
									Quick actions, time-sensitive tasks, alerts, reaching milestones, potential
									errors.
								</td>
								<td>
									Use cautiously and sparingly. Reds can be associated with errors, so use lighter
									oranges or yellows to indicate progress towards a positive goal.
								</td>
							</tr>
						</tbody>
					</DocsTable>
					<Header.H2>Examples</Header.H2>
					<Header.H3>Basic Usage - 0%</Header.H3>
					<Paragraph>
						Illustrates the progress bar's initial or empty state before any progress has been made.
					</Paragraph>
					<Example fn={BasicUsage0Percent} />
					<Header.H3>Basic Usage - 25%</Header.H3>
					<Paragraph>
						Demonstrates the bar at a quarter of completion, showing a small amount of progress.
					</Paragraph>
					<Example fn={BasicUsage25Percent} />
					<Header.H3>Basic Usage - 50%</Header.H3>
					<Paragraph>Showcases the halfway point, representing significant progress.</Paragraph>
					<Example fn={BasicUsage50Percent} />
					<Header.H3>Basic Usage - 75%</Header.H3>
					<Paragraph>
						Indicates that the process is nearing completion, with only a small amount remaining.
					</Paragraph>
					<Example fn={BasicUsage75Percent} />
					<Header.H3>Basic Usage - 100%</Header.H3>
					<Paragraph>
						Signifies full completion of the process, often accompanied by a visual change or
						message.
					</Paragraph>
					<Example fn={BasicUsage100Percent} />
					<Header.H3>Color Range "Cool" - 0%</Header.H3>
					<Paragraph>
						Illustrates the progress bar's initial or empty state before any progress has been made.
					</Paragraph>
					<Example fn={ColorRangeCool0Percent} />
					<Header.H3>Color Range "Cool" - 25%</Header.H3>
					<Paragraph>
						Shows a gradual shift towards darker hues as the bar progresses within the{" "}
						<a href="https://fancy.siteimprove.com/design-tokens/#heatrange:-cool">
							Heatrange: cool colour platte
						</a>
						.
					</Paragraph>
					<Example fn={ColorRangeCool25Percent} />
					<Header.H3>Color Range "Cool" - 50%</Header.H3>
					<Paragraph>
						Highlights the midpoint with a balanced, neutral color within the{" "}
						<a href="https://fancy.siteimprove.com/design-tokens/#heatrange:-cool">
							Heatrange: cool colour platte
						</a>
						.
					</Paragraph>
					<Example fn={ColorRangeCool50Percent} />
					<Header.H3>Color Range "Cool" - 75%</Header.H3>
					<Paragraph>
						Continues the transition to darker colors, nearing completion within the{" "}
						<a href="https://fancy.siteimprove.com/design-tokens/#heatrange:-cool">
							Heatrange: cool colour platte
						</a>
						.
					</Paragraph>
					<Example fn={ColorRangeCool75Percent} />
					<Header.H3>Color Range "Cool" - 100%</Header.H3>
					<Paragraph>
						Indicates completion with the darkest color in the{" "}
						<a href="https://fancy.siteimprove.com/design-tokens/#heatrange:-cool">
							Heatrange: cool colour platte
						</a>
						.
					</Paragraph>
					<Example fn={ColorRangeCool100Percent} />
					<Header.H3>Specific color - 69%</Header.H3>
					<Paragraph>
						Showcases a progress bar at 69% completion using a distinct color that may be chosen to
						highlight a specific milestone, convey a particular meaning, or simply provide visual
						contrast.
					</Paragraph>
					<Example fn={SpecificColor69Percent} />
					<Header.H3>Reverse Animation - 75%</Header.H3>
					<Paragraph>
						Demonstrates a progress bar decreasing from right to left, commonly used for countdowns
						or when a value is depleting.
					</Paragraph>
					<Paragraph>
						<b>Use Cases</b>:
					</Paragraph>
					<Ul
						items={[
							<>
								<b>Decreasing Values</b>: Visualize countdowns, depletion of resources, etc.
							</>,
							<>
								<b>Critical Alerts</b>: Emphasize urgent situations or time-sensitive tasks.
							</>,
						]}
					/>
					<Paragraph>
						<b>Best Practices</b>:
					</Paragraph>
					<Ul
						items={[
							"Use sparingly to avoid confusion.",
							"Clearly label the bar to indicate its reverse direction.",
						]}
					/>
					<Example fn={ReverseAnimation} />
					<Header.H3>Hidden for screen readers</Header.H3>
					<Paragraph>
						Demonstrates how a progress bar can be programmatically hidden from screen reader users
						while still conveying the progress information visually.
					</Paragraph>
					<Example fn={HiddenForScreenReaders} />
					<Header.H2>Properties</Header.H2>
					<Knobs
						component={ProgressBar}
						initialProps={() => ({
							value: 25,
							total: 100,
						})}
					/>
					<Header.H2>Guidelines</Header.H2>
					<Header.H3>Best practices</Header.H3>
					<InlineMessage variant="best-practices">
						<Header.H4>General</Header.H4>
						<Paragraph>
							Use <Code>ProgressBar</Code> when:
						</Paragraph>
						<Ul
							items={[
								<>
									<b>Measurable Progress</b>: The task's progress can be quantifiably tracked
									towards a goal (e.g.: file download). The bar fills linearly from 0% to 100%.
								</>,
								<>
									<b>Compact Display</b>: You need to show progress clearly in limited space (e.g.:
									CMS, or browser extension).
								</>,
								<>
									<b>Data Operations</b>: You want to indicate the status of data-related activities
									(loading, transfer, processing).
								</>,
							]}
						/>
						<Header.H5>Flat Gauge Variation (for Data Visualization)</Header.H5>
						<Ul
							items={[
								<>
									<b>Purpose</b>: Quickly visualize progress towards a metric or goal within a small
									area (e.g.: in dashboards,{" "}
									<GatsbyLink to="/lib/components/Structure/Card">Card</GatsbyLink>,{" "}
									<GatsbyLink to="/lab/components/Text/Highlight">Highlight</GatsbyLink>
									<GatsbyLink to="/lab/components/Tables and lists/List table">
										List table
									</GatsbyLink>
									, <GatsbyLink to="/lab/components/Tables and lists/Table">Table</GatsbyLink>).
								</>,
								<>
									<b>Clear Label</b>: Ensure the metric being tracked is instantly recognizable
									(e.g.: "DCI Score").
								</>,
								<>
									<b>Numerical Precision</b>: Include a percentage or numerical value alongside the
									visual bar for accuracy.
								</>,
							]}
						/>
						<Header.H4>Placement</Header.H4>
						<Paragraph>
							<Code>ProgressBar</Code> is typically used in the following places:
						</Paragraph>
						<Ul
							items={[
								<>
									<b>Above Content</b>: For overall page or section loading.
								</>,
								<>
									<b>Within Task Flows</b>: Guide users through multi-step forms or processes.
								</>,
								<>
									<b>Flat Gauge</b>: In dashboards, Card, Highlight, Table, or List for quick
									progress checks towards goals.
								</>,
							]}
						/>
						<Header.H4>Style</Header.H4>
						<Ul
							items={[
								<>
									<b>Siteimprove Design System</b>: Adhere to Siteimprove's guidelines for color,
									typography, and spacing. If you are not using a component from Fancy, match the
									styling of your <Code>ProgressBar</Code> to existing components for visual
									consistency.
								</>,
								<>
									<b>Label Wrapping</b>: Allow labels to wrap if space is limited, but ensure
									numerical values are always fully visible.
								</>,
							]}
						/>
					</InlineMessage>
					<Header.H3>Do not use when</Header.H3>
					<InlineMessage variant="do-not-use-when">
						<Ul
							items={[
								<>
									Short Tasks ({"<"}5 seconds): Use a{" "}
									<GatsbyLink to="/lib/components/Feedback/spinner">Spinner</GatsbyLink> instead for
									very brief loading times.
								</>,
								<>
									User-Driven Progress: When progress depends on user actions (e.g.: tutorials
									completed) rather than automatic processes.
								</>,
							]}
						/>
					</InlineMessage>
					<Header.H3>Accessibility</Header.H3>
					<InlineMessage variant="accessibility">
						<Header.H4>For designers</Header.H4>
						<Ul items={["Ensure sufficient color contrast between the bar and its background."]} />
						<Header.H4>For developers</Header.H4>
						<Paragraph>
							This component comes with built-in accessibility, no extra work required.
						</Paragraph>
					</InlineMessage>
					<Paragraph>
						Explore detailed guidelines for this component:{" "}
						<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
							Accessibility Specifications
						</a>
					</Paragraph>
					<Header.H3>Writing</Header.H3>
					<InlineMessage variant="writing">
						<Ul
							items={[
								<>
									<b>Labels</b>: Inform users about the specific data or process the progress bar
									represents (e.g.: "Downloading file..."). Keep labels concise and consistent.
								</>,
								<>
									<b>Estimated Time</b>(Optional): For longer tasks, consider displaying an
									estimated time remaining alongside the progress bar.
								</>,
							]}
						/>
					</InlineMessage>
				</TextContainer>
			</ContentSection>
		</>
	);
};
/** @ignore [visual] */
const BasicUsage0Percent = () => <ProgressBar value={0} total={100} />;
/** @ignore [visual] */
const BasicUsage25Percent = () => <ProgressBar value={25} total={100} />;
/** @ignore [visual] */
const BasicUsage50Percent = () => <ProgressBar value={50} total={100} />;
/** @ignore [visual] */
const BasicUsage75Percent = () => <ProgressBar value={75} total={100} />;
/** @ignore [visual] */
const BasicUsage100Percent = () => <ProgressBar value={100} total={100} />;

/** @ignore [visual] */
const ColorRangeCool0Percent = () => <ProgressBar value={0} total={100} colorRange="cool" />;
/** @ignore [visual] */
const ColorRangeCool25Percent = () => <ProgressBar value={25} total={100} colorRange="cool" />;
/** @ignore [visual] */
const ColorRangeCool50Percent = () => <ProgressBar value={50} total={100} colorRange="cool" />;
/** @ignore [visual] */
const ColorRangeCool75Percent = () => <ProgressBar value={75} total={100} colorRange="cool" />;
/** @ignore [visual] */
const ColorRangeCool100Percent = () => <ProgressBar value={100} total={100} colorRange="cool" />;

/** @ignore [visual] */
const SpecificColor69Percent = () => <ProgressBar value={69} total={100} color={ColorPinkDarker} />;

/** @ignore [visual] */
const ReverseAnimation = () => <ProgressBar value={75} total={100} reverseColorRange />;

/** @ignore [visual] */
const HiddenForScreenReaders = () => <ProgressBar value={50} total={100} aria-hidden={true} />;
